<template>
  <div>
    <div v-if="loading" class="flex sm12 lg12">
      <div class="text--center pb-4">
        <div class="flex-center spinner-box">
          <spinner/>
        </div>
      </div>
    </div>
    <div v-if="!loading">
      <!-- Notifications -->
      <div>
        <div class="mb-3">
          <va-notification v-model="errorNotificationVisible" color="danger" input closeable>
            <va-badge color="danger">
              {{ $t('notificationsPage.alerts.error') }}
            </va-badge>
            {{ $t('growing.recipeEdition.saveErrorMessage') }}
          </va-notification>
        </div>
      </div>
      <div class="flex xs12">
        <at-recipe-timeline ref="recipeTimeline"
          :growing-recipe-encrypted="recipeEncrypted"
          :is-production-recipe="false"
          :is-in-modal="false"
          :editable="true"
        />
      </div>
      <div class="flex xs12 text-right">
        <va-button @click="goBack">{{ $t('buttons.cancel') }}</va-button>
        <va-button @click="showSaveModal = true">{{ $t('buttons.save') }}</va-button>
      </div>
      <va-modal
        v-model="showSaveModal"
        :title=" $t('growing.recipeEdition.save') "
        :message=" $t('growing.recipeEdition.saveNewMessage') "
        :noOutsideDismiss="true"
        :hideDefaultActions="true"
        ref="modal"
      >
        <template slot="actions">
          <div class="flex xs12 text-right">
            <va-button @click="cancelModal()" flat color="gray">
              {{ $t('modal.cancel') }}
            </va-button>
            <va-button
              @click="saveRecipe()" color="success">
              {{ $t('modal.confirm') }}
            </va-button>
          </div>
        </template>
      </va-modal>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { structuredApiUrl } from '../../../utilities/socketio'
import { styleHelpers } from '@/services/atmosphere-ui'

export default {
  name: 'recipe-growing-new',
  data () {
    return {
      loading: true,
      recipeEncrypted: {},
      recipeEditable: true,
      valid: null,
      showSaveModal: false,
      errorNotificationVisible: false,
      errorValidRecipeNotificationVisible: false,
    }
  },
  mounted () {
    this.$level.value = 'bird'
    this.validateRoute()
  },
  methods: {
    getGrowingRecipeId () {
      return (parseInt(this.$route.params.recipeId))
    },
    validateRoute () {
      if (this.getGrowingRecipeId() === 0) {
        this.recipeId = 0
        this.recipeEncrypted = {}
        this.valid = true
        this.loading = false
      } else if (this.$route.meta.duplicate) {
        this.getEncryptedRecipe()
      } else {
        this.loading = false
        this.$router.push({ path: '/404/page-not-found' })
      }
    },
    getEncryptedRecipe () {
      axios.get(`${structuredApiUrl}/growing-recipes/${this.getGrowingRecipeId()}`)
        .then(response => {
          this.recipeEncrypted = response.data.data
          const isProductionRecipe = this.recipeEncrypted.tags.some(tag => {
            return tag === 'production-growing-recipe'
          })
          this.valid = !isProductionRecipe
          this.loading = !this.valid
          if (!this.valid) {
            this.loading = false
            this.$router.push({ path: '/404/page-not-found' })
          }
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
          this.$router.push({ path: '/404/page-not-found' })
        })
    },
    saveRecipe () {
      if (this.$refs.recipeTimeline.validateRecipe()) {
        this.$refs.recipeTimeline.addCreatedTimeStamp()
        this.recipeEncrypted.recipe = this.$refs.recipeTimeline.encryptRecipe()

        const saveRecipe = {
          key: this.$refs.recipeTimeline.getRecipeKey(),
          name: this.$refs.recipeTimeline.getRecipeName(),
          recipe: this.recipeEncrypted.recipe,
          description: this.$refs.recipeTimeline.getRecipeDescription(),
          tags: [],
        }

        axios.post(`${structuredApiUrl}/growing-recipes/`, saveRecipe)
          .then(response => {
            if (response.status >= 200 && response.status < 300) {
              this.$store.commit('setSaveRecipe', { showState: true, success: true, recipeNumber: '', message: 'saveSuccessfullyMessage' })
              this.goBack()
            } else {
              this.errorNotificationVisible = true
            }
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        console.log('The recipe is not correct')
      }
      this.cancelModal()
    },
    goBack () {
      this.$router.back()
    },
    cancelModal () {
      this.$refs.modal.cancel()
    },
  },
  computed: {
    computedStyle () {
      const style = {
        borderRadius: styleHelpers.genRadius({ topLeft: 0, topRight: 0, bottomRight: 0, bottomLeft: 0 }),
      }
      return style
    },
  },
}
</script>

<style lang="scss">
.va-card-table.recipes-card .va-card__body {
  padding: 0;
  overflow-y: hidden;
}

.save {
  .va-input__container__input {
    padding-bottom: 0.1em !important;

    &::placeholder {
      text-transform: none;
    }
  }
}

.va-modal__overlay.recipes-modal {
  .va-modal.va-modal--size-large {
    width: 90vw;
    max-width: 90vw;

    .va-modal__inner {
      max-width: 100%;
      padding: 0;

      .title {
        margin-left: 1.25rem;
        font-size: 1rem;
      }
    }

    .va-modal__message {
      width: 100%;
    }
  }
}

</style>
